import { render, staticRenderFns } from "./MultiFreqDiagram.vue?vue&type=template&id=268ab4ae&scoped=true&"
import script from "./MultiFreqDiagram.vue?vue&type=script&lang=ts&"
export * from "./MultiFreqDiagram.vue?vue&type=script&lang=ts&"
import style0 from "./MultiFreqDiagram.vue?vue&type=style&index=0&id=268ab4ae&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "268ab4ae",
  null
  
)

export default component.exports