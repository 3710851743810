export interface Material {
  /** Should be a short unique number that never changes. Will not be visible to the user. */
  id: number;
  /** Short unique material title that will be shown in the user interface. */
  title: string;
  /** Values for the eight frequency bands 62.5, 125, 250, 500, 1000, 2000, 4000, 8000 Hz */
  values: readonly [
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number
  ];
  /** Material description that will be shown in the material details. */
  description: string;
  /** Scientific reference/Source of the material data. */
  source: string;
}
export type MaterialDatabase = Map<
  Material["id"], // number
  Material
>;

const ABSORPTION_MATERIALS: Material[] = [
  {
    id: 200,
    title: "Mauerziegelwerk",
    values: [0.03, 0.03, 0.03, 0.03, 0.04, 0.05, 0.06, 0.06],
    description: "Mauerziegelwand, unverputzt, Fugen ausgestrichen",
    source: "DIN 18041:2016-03, G1, Z.1"
  },
  {
    id: 201,
    title: "Mauerwerk",
    values: [0.11, 0.11, 0.22, 0.34, 0.35, 0.51, 0.43, 0.43],
    description:
      "Mauerwerk, Hochlochziegel, Löcher sichtbar, 60 mm vor Massivwand",
    source: "DIN 18041:2016-03, G1, Z.2"
  },
  {
    id: 202,
    title: "Glattputz",
    values: [0.02, 0.02, 0.02, 0.03, 0.03, 0.04, 0.06, 0.06],
    description: "Glattputz",
    source: "DIN 18041:2016-03, G1, Z.3"
  },
  {
    id: 203,
    title: "Kalkzementputz",
    values: [0.03, 0.03, 0.03, 0.02, 0.04, 0.05, 0.05, 0.05],
    description: "",
    source: "DIN 18041:2016-03, G1, Z.4"
  },
  {
    id: 204,
    title: "Tapete auf Kalkzementputz",
    values: [0.02, 0.02, 0.03, 0.04, 0.05, 0.07, 0.08, 0.08],
    description: "Tapete auf Kalkzementputz",
    source: "DIN 18041:2016-03, G1, Z.5"
  },
  {
    id: 205,
    title: "Spiegel vor Wand",
    values: [0.12, 0.12, 0.1, 0.05, 0.04, 0.02, 0.02, 0.02],
    description: "Spiegel, vor der Wand",
    source: "DIN 18041:2016-03, G1, Z.6"
  },
  {
    id: 206,
    title: "lackierte Holztür",
    values: [0.1, 0.1, 0.08, 0.06, 0.05, 0.05, 0.05, 0.05],
    description: "Tür, Holz, lackiert",
    source: "DIN 18041:2016-03, G1, Z.7"
  },
  {
    id: 207,
    title: "Stuckgips",
    values: [0.02, 0.02, 0.02, 0.03, 0.04, 0.05, 0.05, 0.05],
    description: "Stuckgips, unverputzter Beton",
    source: "DIN 18041:2016-03, G1, Z.8"
  },
  {
    id: 208,
    title: "Marmor, Fliesen, Klinker",
    values: [0.01, 0.01, 0.01, 0.02, 0.02, 0.03, 0.03, 0.03],
    description: "Marmor, Fliesen, Klinker",
    source: "DIN 18041:2016-03, G1, Z.9"
  },
  {
    id: 209,
    title: "Fenster+Isolierverglasung ",
    values: [0.28, 0.28, 0.2, 0.11, 0.06, 0.03, 0.02, 0.02],
    description: "Fenster (Isolierverglasung)",
    source: "DIN 18041:2016-03, G1, Z.10"
  },
  {
    id: 210,
    title: "Aufgeklebter Parkettfußboden",
    values: [0.04, 0.04, 0.04, 0.05, 0.06, 0.06, 0.06, 0.06],
    description: "Parkettfußboden (aufgeklebt)",
    source: "DIN 18041:2016-03, G1, Z.11"
  },
  {
    id: 211,
    title: "Parkettfußboden auf Blindboden",
    values: [0.2, 0.2, 0.15, 0.1, 0.09, 0.06, 0.1, 0.1],
    description: "Parkettfußboden, auf Blindboden",
    source: "DIN 18041:2016-03, G1, Z.12"
  },
  {
    id: 212,
    title: "Parkettfußboden hohlliegend ",
    values: [0.15, 0.15, 0.08, 0.07, 0.06, 0.06, 0.06, 0.06],
    description: "Parkettfußboden, hohlliegend",
    source: "DIN 18041:2016-03, G1, Z.13"
  },
  {
    id: 213,
    title: "Teppichboden bis 6mm Florhöhe",
    values: [0.02, 0.02, 0.04, 0.07, 0.19, 0.29, 0.35, 0.35],
    description: "Teppichboden, bis 6mm Florhöhe, auf massivem Untergrund",
    source: "DIN 18041:2016-03, G1, Z.14"
  },
  {
    id: 214,
    title: "Teppichboden 7-10mm Florhöhe",
    values: [0.04, 0.04, 0.07, 0.14, 0.3, 0.51, 0.78, 0.78],
    description: "Teppichboden, 7mm bis 10mm Florhöhe, auf massivem Untergrund",
    source: "DIN 18041:2016-03, G1, Z.15"
  },
  {
    id: 215,
    title: "Nadelfilz 7mm",
    values: [0.02, 0.02, 0.04, 0.12, 0.2, 0.36, 0.57, 0.57],
    description: "Nadelfilz 7mm, auf massivem Untergrund",
    source: "DIN 18041:2016-03, G1, Z.16"
  },
  {
    id: 216,
    title: "Teppich mit Filzunterlage",
    values: [0.07, 0.07, 0.21, 0.57, 0.68, 0.81, 0.72, 0.72],
    description: "5mm Teppich mit 5mm Filzunterlage, auf massivem Untergrund",
    source: "DIN 18041:2016-03, G1, Z.17"
  },
  {
    id: 217,
    title: "PVC Bodenbelag",
    values: [0.01, 0.01, 0.02, 0.01, 0.03, 0.05, 0.05, 0.05],
    description: "PVC-Bodenbelag (2,5mm) auf Betonboden",
    source: "DIN 18041:2016-03, G1, Z.18"
  },
  {
    id: 218,
    title: "Linoleum auf Beton",
    values: [0.02, 0.02, 0.02, 0.03, 0.03, 0.04, 0.04, 0.04],
    description: "Linoleum auf Beton",
    source: "DIN 18041:2016-03, G1, Z.19"
  },
  {
    id: 219,
    title: "Furnierte Holz/Spanplatte",
    values: [0.04, 0.04, 0.04, 0.05, 0.06, 0.06, 0.06, 0.06],
    description: "Furnierte Holz-oder Spanplatte dicht vor festem Untergrund",
    source: "DIN 18041:2016-03, G1, Z.20"
  },
  {
    id: 220,
    title: "Hartfaserplatte ohne Dämmstoff, Wandabstand 60mm",
    values: [0.22, 0.22, 0.19, 0.13, 0.07, 0.05, 0.05, 0.05],
    description:
      "4mm Hartfaserplatte, kassettiert ohne Dämmstoff, Wandabstand 60mm",
    source: "DIN 18041:2016-03, G1, Z.21"
  },
  {
    id: 221,
    title: "Hartfaserplatte mit Mineralwollplatte",
    values: [0.63, 0.63, 0.25, 0.14, 0.08, 0.06, 0.05, 0.05],
    description:
      "4mm Hartfaserplatte, kassettiert mit 40mm Mineralwollplatte, Wandabstand 60mm",
    source: "DIN 18041:2016-03, G1, Z.22"
  },
  {
    id: 222,
    title: "Hartfaserplatte ohne Dämmstoff, Wandabstand 120mm",
    values: [0.26, 0.26, 0.15, 0.07, 0.05, 0.05, 0.05, 0.05],
    description:
      "4mm Hartfaserplatte, kassettiert ohne Dämmstoff, Wandabstand 120mm",
    source: "DIN 18041:2016-03, G1, Z.23"
  },
  {
    id: 223,
    title: "Sperrholzplatte, 40mm Mineralwolle",
    values: [0.77, 0.77, 0.33, 0.16, 0.08, 0.07, 0.03, 0.03],
    description: "4mm Sperrholzplatte, 40mm Mineralwolle, Wandabstand 120mm",
    source: "DIN 18041:2016-03, G1, Z.24"
  },
  {
    id: 224,
    title: "Gipsplatte, Wandabstand 25mm",
    values: [0.27, 0.27, 0.17, 0.1, 0.09, 0.11, 0.12, 0.12],
    description: "Gipsplatte, 9,5mmstark, Wandabstand 25mm",
    source: "DIN 18041:2016-03, G1, Z.25"
  },
  {
    id: 225,
    title: "Gipsplatte, Wandabstand 60mm",
    values: [0.3, 0.3, 0.1, 0.05, 0.07, 0.09, 0.08, 0.08],
    description:
      "Gipsplatte, 9,5mm stark, Wandabstand 60mm, Hohlraum kassettiert",
    source: "DIN 18041:2016-03, G1, Z.26"
  },
  {
    id: 226,
    title: "Kinoleinwand",
    values: [0.1, 0.1, 0.13, 0.36, 0.31, 0.29, 0.2, 0.2],
    description: "Kino-Bildwand",
    source: "DIN 18041:2016-03, G1, Z.27"
  },
  {
    id: 227,
    title: "Bühnenöffnung mit Deko",
    values: [0.4, 0.4, 0.43, 0.57, 0.7, 0.8, 0.8, 0.8],
    description: "Bühnenöffnung mit Dekoration",
    source: "DIN 18041:2016-03, G1, Z.28"
  },
  {
    id: 228,
    title: "Bücherregal in Bibliothek",
    values: [0.3, 0.3, 0.39, 0.39, 0.31, 0.29, 0.21, 0.21],
    description: "Bücherregal in Bibliotheken (je m2 Oberfläche)",
    source: "DIN 18041:2016-03, G1, Z.29"
  },
  {
    id: 229,
    title: "Lochsteine",
    values: [0.1, 0.1, 0.2, 0.44, 0.55, 0.44, 0.22, 0.22],
    description: "Lochsteine (vorsichtige Annahme)",
    source: "DIN 18041:2016-03, G1, Z.30"
  },
  {
    id: 230,
    title: "Hartfaserplatte, 3,5mm",
    values: [0.61, 0.61, 0.26, 0.19, 0.07, 0.07, 0.03, 0.03],
    description:
      "3,5mm Hartfaserplatte, 40mm Mineralwolle, 30mm Holzleisten 750x500mm Kasten",
    source: "DIN 18041:2016-03, G1, Z.31"
  },
  {
    id: 231,
    title: "Mineralplatte mit Vlieskaschierung ",
    values: [0.05, 0.05, 0.65, 0.7, 0.85, 1, 1, 1],
    description:
      "15mm Mineralplatte mit Vlieskaschierung, 200mm zur Rohdecke, ohnerückseitige Zusatzmaßnahmen",
    source: "DIN 18041:2016-03, G1, Z.32"
  },
  {
    id: 232,
    title: "Mineralplatte fein genadelt",
    values: [0.35, 0.35, 0.55, 0.65, 0.7, 0.65, 0.6, 0.6],
    description:
      "15mm Mineralplatte fein genadelt, 200mm zur Rohdecke, ohne rückseitige Zusatzmaßnahmen",
    source: "DIN 18041:2016-03, G1, Z.33"
  },
  {
    id: 233,
    title: "Mineralplatte ungenadelt",
    values: [0.15, 0.15, 0.15, 0.1, 0.15, 0.15, 0.1, 0.1],
    description:
      "15mm Mineralplatte ungenadelt, 200mm zur Rohdecke, ohne rückseitige Zusatzmaßnahmen",
    source: "DIN 18041:2016-03, G1, Z.34"
  },
  {
    id: 234,
    title: "Metallkassette gelocht ",
    values: [0.35, 0.35, 0.7, 0.85, 0.75, 0.75, 0.75, 0.75],
    description:
      "gelochte Metallkassette, 16% Lochflächenanteil, 2,5mm Lochdurchmesser, Akustikvlies, 200mm zur Rohdecke, ohne rückseitige Zusatzmaßnahmen",
    source: "DIN 18041:2016-03, G1, Z.35"
  },
  {
    id: 235,
    title: "Glaswollplatte mit Farbbeschichtung",
    values: [0.75, 0.75, 0.95, 0.95, 1, 1, 1, 1],
    description:
      "20mm Recycle Glaswollplatte mit mikroporöser Farbbeschichtung, 200mm zur Rohdecke, 100 rückseitiger Tiefenabsorber",
    source: "DIN 18041:2016-03, G1, Z.36"
  },
  {
    id: 236,
    title: "Glaswollplatte mit Vliesbeschichtung",
    values: [0.4, 0.4, 0.85, 1, 0.9, 1, 1, 1],
    description:
      "15mm Recycle Glaswollplatte, Vliesbeschichtung, 200mm zur Rohdecke, ohne rückseitige Zusatzmaßnehmen",
    source: "DIN 18041:2016-03, G1, Z.37"
  },
  {
    id: 237,
    title: "Spanndecke mikroperforier, kein Akustikvlies im Hohlraum",
    values: [0.09, 0.09, 0.25, 0.65, 0.8, 0.61, 0.6, 0.6],
    description:
      "Spanndecke mikroperforiert, 100mm Abstand bzw. Luftschicht, kein Akustikflies im Hohlraum",
    source: "DIN 18041:2016-03, G1, Z.38"
  },
  {
    id: 238,
    title: "Spandecke mikroperforiert, Akustikvlies an Rückwand",
    values: [0.28, 0.28, 0.69, 1, 0.89, 0.75, 0.71, 0.71],
    description:
      "Spanndecke mikroperforiert, 100mm Abstand bzw. Luftschicht, 40mm Akustikvlies direkt an Rückwand",
    source: "DIN 18041:2016-03, G1, Z.39"
  },
  {
    id: 239,
    title:
      "Gipsplatten Rasterdecke, ohne Mineralwollauflage, 15,5% Lochflächenanteil",
    values: [0.45, 0.45, 0.6, 0.7, 0.6, 0.55, 0.65, 0.65],
    description:
      "Gipsplatten Rasterdecke 8/18 Rundlochung, 15,5% Lochflächenanteil, 200mm zur Rohdecke, Akustikvlies, ohne Mineralwollauflage",
    source: "DIN 18041:2016-03, G1, Z.40"
  },
  {
    id: 240,
    title:
      "Gipsplatten Rasterdecke, 20 mm Mineralwollauflage, 15,5% Lochflächenanteil ",
    values: [0.5, 0.5, 0.65, 0.7, 0.65, 0.6, 0.7, 0.7],
    description:
      "Gipsplatten Rasterdecke 8/18 Rundlochung, 15,5% Lochflächenanteil, 200mm zur Rohdecke, Akustikvlies, 20mm Mineralwollauflage",
    source: "DIN 18041:2016-03, G1, Z.41"
  },
  {
    id: 241,
    title: "Gipsplatten Rasterdecke, 200mm zur Rohdecke, 7,8% Locflächenanteil",
    values: [0.45, 0.45, 0.5, 0.5, 0.4, 0.35, 0.35, 0.35],
    description:
      "Gipsplatten Rasterdecke 12/25 Quadratlochung, 7,8% Lochflächenanteil, 200mm zur Rohdecke, Akustikvlies, 20mm Mineralwollauflage",
    source: "DIN 18041:2016-03, G1, Z.42"
  },
  {
    id: 242,
    title: "Gipsplatten Rasterdecke, 65mm zur Rohdecke, 7,8% Lochflächenanteil",
    values: [0.4, 0.4, 0.45, 0.5, 0.45, 0.4, 0.3, 0.3],
    description:
      "Gipsplatten Rasterdecke 12/25 Quadratlochung, 7,8% Lochflächenanteil, 65mm zur Rohdecke, Akustikvlies, 20mm Mineralwollauflage",
    source: "DIN 18041:2016-03, G1, Z.43"
  },
  {
    id: 243,
    title: "Holzwolle Leichtbauplatten, 35mm",
    values: [0.08, 0.08, 0.17, 0.7, 0.71, 0.64, 0.64, 0.64],
    description: "Holzwolle Leichtbauplatten 35mm, direkt auf Wand",
    source: "DIN 18041:2016-03, G1, Z.44"
  },
  {
    id: 244,
    title: "Holzwolle Leichtbauplatten, 25mm",
    values: [0.13, 0.13, 0.42, 0.54, 0.45, 0.7, 0.73, 0.73],
    description:
      "Holzwolle Leichtbauplatten 25mm, Hohlraum leer, Wandabstand 50mm",
    source: "DIN 18041:2016-03, G1, Z.45"
  },
  {
    id: 245,
    title: "Melaminharz-Schaumstoff, 30mm dick",
    values: [0.12, 0.12, 0.31, 0.66, 0.86, 0.87, 0.92, 0.92],
    description:
      "Melaminharz-Schaumstoff, Rohdichte 8kg/m3 bis 10 kg/m3, 30mm dick",
    source: "DIN 18041:2016-03, G1, Z.46"
  },
  {
    id: 246,
    title: "Melaminharz-Schaumstoff, 50mm dick",
    values: [0.16, 0.16, 0.56, 0.87, 0.96, 0.97, 0.97, 0.97],
    description:
      "Melaminharz-Schaumstoff, Rohdichte 8kg/m3 bis 10 kg/m3, 50mm dick",
    source: "DIN 18041:2016-03, G1, Z.47"
  },
  {
    id: 247,
    title: "Mineralwollmatte, ohne Lochabdeckung, 40mm",
    values: [0.11, 0.11, 0.36, 0.68, 0.88, 0.89, 0.97, 0.97],
    description: "40mm Mineralwollmatte (20kg/m3), ohne Lochblechabdeckung",
    source: "DIN 18041:2016-03, G1, Z.48"
  },
  {
    id: 248,
    title: "Mineralwollmatte, mit Lochabdeckung, 40mm",
    values: [0.11, 0.11, 0.36, 0.69, 0.95, 0.81, 0.7, 0.7],
    description:
      "40mm Mineralwollmatte (20kg/m3), mit Lochblechabdeckung (18%)",
    source: "DIN 18041:2016-03, G1, Z.49"
  },
  {
    id: 249,
    title: "Gipsplatte, 8/18 Rundlochung ",
    values: [0.12, 0.12, 0.28, 0.75, 0.5, 0.38, 0.3, 0.3],
    description:
      "Gipsplatte 9,5mm, 8/18 Rundlochung, 15% Lochflächenanteil, mit Faservlies hinterlegt, Abstand 100mm",
    source: "DIN 18041:2016-03, G1, Z.50"
  },
  {
    id: 250,
    title: "Gipsplatte geschlitzt, mit Faservlies",
    values: [0.11, 0.11, 0.28, 0.66, 0.38, 0.28, 0.3, 0.3],
    description:
      "Gipsplatte, geschlitzt, 8,8% offene Fläche, mit Faservlies hinterlegt , Abstand 100mm",
    source: "DIN 18041:2016-03, G1, Z.51"
  },
  {
    id: 251,
    title: "Langfeld-Metallkassette, gelocht",
    values: [0.38, 0.38, 0.65, 0.59, 0.75, 0.77, 0.61, 0.61],
    description:
      "gelochte Langfeld-Metallkassette, 20% Lochflächenanteil, 3mm Lochdurchmesser, Akustikfilz, 300mm Abstand",
    source: "DIN 18041:2016-03, G1, Z.52"
  }
];

const ABSORPTION_MATERIALS_ADD: Material[] = [
  {
    id: 252,
    title: "Prallwand HARO Protect Light 19",
    values: [0.05, 0.05, 0.15, 0.35, 0.75, 0.85, 0.45, 0.45],
    description:
      "Prallwandsystem, Deckschicht aus gelochtem Sperrholz, rückseitige Schaumstoffkaschierung",
    source: "Müller-BBM Prüfbericht Nr. M125816/1"
  },
  {
    id: 253,
    title: "HARO Protect Classic Sperrholz, Breite: 300mm",
    values: [0.35, 0.35, 0.9, 0.85, 0.55, 0.55, 0.65, 0.65],
    description:
      "Flächenelastische Prallwand mit gelochter Oberfläche. 50mm Polyesterfaserdämmung",
    source: "Müller-BBM Prüfbericht Nr. M125816/2 S.1"
  },
  {
    id: 254,
    title: "HARO Protect Classic Sperrholz, Breite: 1215mm",
    values: [0.3, 0.3, 0.8, 0.9, 0.65, 0.6, 0.55, 0.55],
    description:
      "Prallwandsystem, Deckschicht aus gelochtem Sperrholz, rückseitige Schaumstoffkaschierung",
    source: "Müller-BBM Prüfbericht Nr. M125816/2 S.2"
  },
  {
    id: 255,
    title: "Heradesign Superfine 25 mm",
    values: [0.25, 0.25, 0.4, 0.7, 0.65, 0.6, 0.65, 0.65],
    description:
      "Tiefe: 95 mm. Ohne Mineraldämmung. Magnesitgebundene Holzwolle-Akustikplatte (Faserbreite 1 mm)",
    source: "Unbekannt"
  },
  {
    id: 256,
    title: "HARO Protect Classic solid",
    values: [0.2, 0.2, 0.1, 0.05, 0.05, 0.1, 0.1, 0.1],
    description: "Flächenelastische Prallwand HARO Protect Classic Sperrholz",
    source: "Hersteller"
  },
  {
    id: 257,
    title: "HARO Protect Classic Sperrholz geschlitzt ohne Vlies",
    values: [0.2, 0.2, 0.8, 0.6, 0.3, 0.25, 0.3, 0.3],
    description:
      "Flächenelastische Prallwand HARO Protect Classic Sperrholz geschlitzt 30 mm Mineralfaserdämmung",
    source: "Hersteller"
  },
  {
    id: 258,
    title: "10% Absorbent",
    values: [0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1],
    description: "",
    source: ""
  },
  {
    id: 259,
    title: "25% Absorbent",
    values: [0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25],
    description: "",
    source: ""
  },
  {
    id: 260,
    title: "50% Absorbent",
    values: [0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5],
    description: "",
    source: ""
  },
  {
    id: 261,
    title: "75% Absorbent",
    values: [0.75, 0.75, 0.75, 0.75, 0.75, 0.75, 0.75, 0.75],
    description: "",
    source: ""
  },
  {
    id: 262,
    title: "100% Absorbent",
    values: [1, 1, 1, 1, 1, 1, 1, 1],
    description: "",
    source: ""
  }
];
var ABSORPTION_MATERIALS_TOTAL = ABSORPTION_MATERIALS.concat(
  ABSORPTION_MATERIALS_ADD
);

export var ABSORPTION_DATABASE: MaterialDatabase = new Map(
  ABSORPTION_MATERIALS_TOTAL.map(m => [m.id, m])
);

export function getNew_ABSORPTION_MATERIALS_ID(): number {
  return (
    ABSORPTION_MATERIALS_TOTAL[ABSORPTION_MATERIALS_TOTAL.length - 1].id + 1
  );
}

export function add_ABSORPTION_MATERIAL(newMat: Material) {
  ABSORPTION_MATERIALS_TOTAL.push(newMat);
  ABSORPTION_DATABASE = new Map(ABSORPTION_MATERIALS_TOTAL.map(m => [m.id, m]));
}

const SCATTERING_MATERIALS: Material[] = [
  {
    id: 25200,
    title: "eben",
    values: [0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08],
    description: "eben",
    source: "Fraunhofer IBP, Zhou et al., 2021"
  },
  {
    id: 25201,
    title: "leicht strukturiert",
    values: [0.01, 0.015, 0.025, 0.05, 0.085, 0.06, 0.05, 0.045],
    description: "leicht strukturiert",
    source: "Fraunhofer IBP, Zhou et al., 2021"
  },
  {
    id: 25202,
    title: "stark strukturiert",
    values: [0.02, 0.03, 0.05, 0.1, 0.17, 0.12, 0.1, 0.09],
    description: "stark strukturiert",
    source: "Fraunhofer IBP, Zhou et al., 2021"
  }
];

export const SCATTERING_DATABASE: MaterialDatabase = new Map(
  SCATTERING_MATERIALS.map(m => [m.id, m])
);

export const FALLBACK_ABSORPTION_MATERIAL: Material["id"] = 202;
export const FALLBACK_SCATTERING_MATERIAL: Material["id"] = 25200;
