import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Evolution4 from "../views/Evolution4.vue";
import Calculation from "../views/Calculation.vue";
import Results from "../views/Results.vue";
import BookmarkError from "../views/BookmarkError.vue";
import { store } from "@/state/state";

Vue.use(VueRouter);

/** Default page title that will appear in browser tab and bookmarks. Will be extended by the routes below. */
const DEFAULT_TITLE = "reverberate light";

/**
 * It's part of the concept that many routes use the same path. This is because not
 * all routes should create a history entry that will be accessible for users via url.
 * Note that the order of the routes array is important, because the first matched path
 * will be the matched route!
 * To link to a specific route internally the app uses programmatic navigation via
 * the name of the routes, e.g. {name: Results}
 * Navigation guards inside the routes guarantee that results page is only shown, if
 * a valid calculation result is in store.
 */
const routes: Array<RouteConfig> = [
  {
    /** This route must be first in the list. It only matches if there are letters behind "/calc/": */
    path: "/calc/:bookmark",
    name: "OpenFromBookmark",
    beforeEnter: (to, from, next) => {
      try {
        console.log("trying to open bookmark", to.params.bookmark);
        store.openBookmark(to.params.bookmark);
        next({ name: "Results", replace: true });
      } catch (error) {
        store.setError(error as Error);
        next({ name: "Error" });
      }
    }
  },
  {
    /** The Results page also matches for "/calc" if a result is in store. Else redirects to "Calculation" */
    path: "/calc",
    name: "Results",
    component: Results,
    beforeEnter: (to, from, next) => {
      if (store.result === null) {
        console.log("store.result is null. Redirecting to Calculation...");
        next({ name: "Calculation", replace: true });
        return;
      }
      const bookmark = store.getBookmarkCode();
      if (bookmark) {
        console.log("navigating to results with bookmark", bookmark);
        next({
          name: "ResultsWithBookmark",
          params: { bookmark },
          replace: true
        });
      } else {
        next();
      }
    },
    meta: { scrollBelowHeader: true }
  },
  {
    /**
     * This route is only reached programmatically. It is used to write
     * the bookmark from application state to the browser url.
     * To open a bookmark from url the route "OpenFromBookmark" is used.
     */
    path: "/calc/:bookmark",
    name: "ResultsWithBookmark",
    meta: {
      title: <titleFunction>(to: Route) =>
        `Reverberation Report ${to.params.bookmark.substring(0, 4)}...`,
      scrollBelowHeader: true
    },
    component: Results,
    beforeEnter: (to, from, next) => {
      console.log("resultswithbookmark!");
      // eslint-disable-next-line no-constant-condition
      if (store.result === null) {
        console.log("store.result is null. Redirecting to Calculation...");
        next({ name: "Calculation", replace: true });
      } else {
        next();
      }
    }
  },
  {
    /** Page for editing the current room configuration. */
    path: "/calc",
    name: "Calculation",
    component: Calculation,
    props: route => ({ jumpToSection: route.params.jumpToSection }),
    children: []
  },
  {
    path: "/new",
    name: "New",
    beforeEnter: (to, from, next) => {
      // Todo: Confirm discarding if there is a calculation in store
      store.resetCalculation();
      next({ name: "Calculation", replace: true });
    }
  },
  {
    /** Legacy / Debugging only! */
    path: "/evolutions/4",
    name: "Evolution4",
    component: Evolution4
  },
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/error",
    name: "Error",
    meta: { title: "Error" },
    component: BookmarkError,
    beforeEnter: (to, from, next) => {
      if (store.error === null) {
        next({ name: "Home", replace: true });
      } else {
        next();
      }
    }
  },
  {
    path: "/about",
    name: "About",
    meta: { title: "About" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/contact",
    name: "Contact",
    meta: { title: "Contact" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Contact.vue")
  },
  {
    path: "*",
    redirect: "/"
  }
];

const router = new VueRouter({
  routes,
  scrollBehavior(to) {
    if (to.meta?.scrollBelowHeader) {
      console.log("scroll below header");
      console.log(document.querySelector("#pageTop"));
      return { selector: "#pageTop" };
    }
    // scroll to top on navigation. see https://router.vuejs.org/guide/advanced/scroll-behavior.html
    return { x: 0, y: 0 };
  }
});

type titleFunction = (to: Route) => string;

/** Update page title */
router.afterEach(to => {
  let title = "";
  if (to.meta?.title) {
    if (typeof to.meta.title === "function") {
      title = (<titleFunction>to.meta.title)(to);
    } else if (typeof to.meta.title === "string") {
      title = to.meta.title;
    }
  }
  if (title) {
    title += " | ";
  }
  document.title = title + DEFAULT_TITLE;
});

export default router;
