import { RTDeviationRule } from "@/calc/din-requirements";
import { OctoFrequential } from "@/calc/room";

/** Defines a bullet preset of how a point is drawn in a diagram */
export interface BulletStyle {
  /** SVG path definition */
  bullet: string;
}

/** Defines a style preset of how a line/target/corridor is drawn inside the diagram */
export interface DrawingStyle {
  /** SVG path definition how to draw the legend icon  */
  path: string;
  /** Draw filling or not */
  filled: boolean;
  /** Corresponds with SVG fill-opacity */
  fillOpacity: number;
  /** Draw stroke or not */
  stroked: boolean;
  /** Corresponds with SVG stroke-opacity */
  strokeOpacity: number;
  /** Corresponds with SVG stroke-width */
  strokeWidth: number | undefined;
  /** Hex color that should be used for stroke and fill */
  color: string | undefined;
  /** Corresponds with SVG stroke-dasharray, but as a number array */
  dasharray: number[] | undefined;
  /** Corresponds with SVG stroke-linecap */
  linecap: string | undefined;
}

/**  */
export interface LegendItem {
  /** text that labels the icon in the legend */
  label: string;
  /** Which drawingStyle preset to use */
  drawingStyle: drawingType;
  /** Which bullet preset to use */
  bullet: bulletType | null;
  /** Use a stronger drawing style with filled bullets*/
  dominant: boolean;
}

/** OctoFrequential value set with information how it should be drawn in a diagram */
export interface OctoValuePlot extends LegendItem {
  values: Readonly<OctoFrequential<number>>;
}

/** Valid bullet preset name */
export type bulletType = "circle" | "triangle" | "square";

/**
 * Contains SVG-Path definitions of bullets with 5px radius that indicate a datapoint in a diagram
 */
export const BULLET_STYLES: Record<bulletType, BulletStyle> = {
  circle: { bullet: "M6 0 A 6 6 0 0 0 -6 0 A6 6 0 0 0 6 0 Z" },
  triangle: { bullet: "M0 7.5 L6.5 -3.75 L-6.5 -3.75 Z" },
  square: { bullet: "M5.3 5.3 L-5.3 5.3 L-5.3 -5.3 L5.3 -5.3 Z" }
};

/** Radius of the legend icons (the pivot point is in the center of the legend icon) */
export const LEGEND_ICON_OFFSET = 12;
/** The height of the legend (symbol) */
export const LEGEND_LINE_HEIGHT = LEGEND_ICON_OFFSET * 3;

/** Valid drawingStyle preset name */
export type drawingType =
  | "corridor"
  | "target"
  | "line"
  | "plot"
  | "realRT"
  | "virtualRT";

/**
 * Contains 24x24px SVG-Path definitios of legend icons for different indicators
 */
export const DRAWING_STYLES: Record<drawingType, DrawingStyle> = {
  corridor: {
    path: "M-12 -12 L12 -12 L12 12 L-12 12 Z",
    filled: true,
    fillOpacity: 0.8,
    stroked: false,
    strokeOpacity: 1,
    strokeWidth: undefined,
    dasharray: undefined,
    linecap: "butt",
    color: "#35ae8e"
  },
  target: {
    path: "M-12 0 L12 0",
    filled: false,
    stroked: true,
    fillOpacity: 1,
    strokeOpacity: 1,
    strokeWidth: 2,
    dasharray: [5],
    linecap: "butt",
    color: "#005f44"
  },
  line: {
    path: "M-12 0 L12 0",
    filled: false,
    stroked: true,
    fillOpacity: 1,
    strokeOpacity: 1,
    strokeWidth: 2,
    dasharray: undefined,
    linecap: "butt",
    color: "#000000"
  },
  plot: {
    path: "M-12 0 L12 0",
    filled: false,
    stroked: true,
    fillOpacity: 1,
    strokeOpacity: 1,
    strokeWidth: 2,
    dasharray: undefined,
    linecap: "butt",
    color: "#0069a5"
  },
  realRT: {
    path: "M-12 0 L0 0 M0 -12 L0 0 M-12 -12 L0 0",
    filled: false,
    stroked: true,
    fillOpacity: 1,
    strokeOpacity: 1,
    strokeWidth: 2,
    dasharray: undefined,
    linecap: "butt",
    color: "#000000"
  },
  virtualRT: {
    path: "M-12 0 L0 0 M0 -12 L0 0 M-12 -12 L0 0",
    filled: false,
    stroked: true,
    fillOpacity: 1,
    strokeOpacity: 1,
    strokeWidth: 2,
    dasharray: [0, 3],
    linecap: "round",
    color: "#000000"
  }
};

/** Returns the transformed 2d points of the given octofrequential values */
export function getMultiFreqLine(
  points: Readonly<OctoFrequential<number>>,
  projectX: projectionFunction,
  projectY: projectionFunction
): OctoFrequential<[number, number]> {
  return points.map((fVal, fIdx) => [
    projectX(fIdx),
    projectY(fVal)
  ]) as OctoFrequential<[number, number]>;
}

/** Returns a SVG path that connects the given points with a polyline */
export function svgPathFromPoints(
  points: OctoFrequential<[number, number]>
): string {
  return "M" + points.map(p => `${p[0]} ${p[1]}`).join(" L");
}

/**
 * Layout multiple legend icons horizontally:
 * Distributes the given width between the given items
 * @returns an array of x positions of the legend icons
 */
export function getLegendPositions(
  itemsCount: number,
  width: number,
  itemsPerLine: number
): [number, number][] {
  return Array.from({ length: itemsCount }, (_, i) => {
    const col = i % itemsPerLine;
    const row = Math.floor(i / itemsPerLine);
    return [
      (col / itemsPerLine) * width + LEGEND_ICON_OFFSET,
      LEGEND_ICON_OFFSET + LEGEND_ICON_OFFSET + row * LEGEND_LINE_HEIGHT
    ];
  });
}

/**
 * Calculates how much space the legend will take vertically.
 * Takes number of legend items into account to determine how
 * many rows will be used.
 * */
export function getLegendHeight(
  itemsCount: number,
  itemsPerLine: number
): number {
  const lastRow = Math.floor((itemsCount - 1) / itemsPerLine);
  return (
    LEGEND_ICON_OFFSET +
    lastRow * LEGEND_LINE_HEIGHT +
    LEGEND_ICON_OFFSET +
    LEGEND_ICON_OFFSET
  );
}

/** Returns the SVG path definition of the line that indicates the DIN 18041 target */
export function getDINTargetRTPath(
  dinTargetRT: number,
  projectX: projectionFunction,
  projectY: projectionFunction
): string {
  const left = projectX(0) - 5;
  const right = projectX(7) + 5;
  const y = projectY(dinTargetRT);
  return `M${left} ${y} L${right} ${y}`;
}

type projectionFunction = (val: number) => number;

/** SVG path definition of the polygon shape that visualizes the DIN 18041 tolerances */
export function getDINCorridorPath(
  dinTargetRT: number,
  corridor: OctoFrequential<RTDeviationRule>,
  projectX: projectionFunction,
  projectY: projectionFunction
): string {
  const upperFence = corridor.map(
    (rule, fIdx) =>
      `${projectX(fIdx)} ${projectY(rule.maxDeviation * dinTargetRT)}`
  );
  const lowerFence = corridor.map(
    (rule, fIdx) =>
      `${projectX(fIdx)} ${projectY(rule.minDeviation * dinTargetRT)}`
  );
  return `M${upperFence.join(" L")} L${lowerFence.reverse().join(" L")} Z`;
}

/** https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/viewBox */
export interface SVGViewBox {
  x: number;
  y: number;
  width: number;
  height: number;
}

/** Calculates the SVG viewBox of the diagram SVG element */
export function calculateSVGViewbox(
  width: number,
  height: number,
  margin: number,
  legendHeight: number
): SVGViewBox {
  return {
    x: -margin,
    y: -margin,
    width: width + margin + margin,
    height: height + margin + margin + legendHeight
  };
}
/** Calculates the positions on an axis that should be marked with a tick */
export function calculateAxisMarks(
  min: number,
  max: number,
  /** Draw a mark every ? units. */
  res: number
): number[] {
  const totalMarks = Math.floor(Math.abs(max - min) / res) + 1;
  const firstMark = Math.ceil(min / res);
  return Array.from({ length: totalMarks }, (_, i) => (firstMark + i) * res);
}

/**
 * Constructs horizontal grid lines from the given y-positions and joins
 * them in a single SVG path definition.
 * */
export function gridYPath(
  yMarks: number[],
  xMin: number,
  xMax: number,
  projectX: projectionFunction,
  projectY: projectionFunction
): string {
  return yMarks
    .map(
      y => `M${projectX(xMin)} ${projectY(y)} L${projectX(xMax)} ${projectY(y)}`
    )
    .join(" ");
}

/**
 * Constructs vertical grid lines from the given x-positions and joins
 * them in a single SVG path definition.
 * */
export function gridXPath(
  xMarks: number[],
  yMin: number,
  yMax: number,
  projectX: projectionFunction,
  projectY: projectionFunction
): string {
  return xMarks
    .map(
      x => `M${projectX(x)} ${projectY(yMin)} L${projectX(x)} ${projectY(yMax)}`
    )
    .join(" ");
}

/** Calculates a suitable time-window for displaying the slopes */
export function maxTimeWindow(decayTimes: number[] | undefined): number {
  if (decayTimes) {
    return Math.ceil(decayTimes.reduce((a, b) => Math.max(a, b)) * 1.5);
  } else {
    return 10;
  }
}

export const DIN_TARGET_LEGEND_ITEMS: LegendItem[] = [
  {
    label: "DIN 18041 Target",
    drawingStyle: "target",
    bullet: null,
    dominant: true
  },
  {
    label: "DIN 18041 Tolerance",
    drawingStyle: "corridor",
    bullet: null,
    dominant: false
  }
];
