import {
  Atmosphere,
  FREQUENCIES,
  FrequencyIndex,
  W_0
} from "@/calc/acoustic-constants";
import { DetailedPropertyGroup } from "@/calc/result-log";
import { RoomDirectionMultiFreq, TriDimensional } from "@/calc/room";
import { DetailedResult } from "@/calc/sound-pressure-summed";
import { soundPressureLevel } from "@/calc/decibel";
import { numberOfWallReflections } from "@/calc/sound-pressure-directional";

/**
 * Produces a grouped detailed result log for one selected frequency that
 * can be used for printing in the user interface or exporting for excel.
 * @param atmosphereInput Atmosphere
 * @param roomInput Frequency-specific room input
 * @param timeInput point of time
 * @param frequencyIndex Frequency index corresponding to one of eight octave bands
 * @param result detailed sound pressure result
 */
export function singleFreqResultLog(
  atmosphereInput: Atmosphere,
  roomInput: TriDimensional<RoomDirectionMultiFreq>,
  timeInput: number,
  frequencyIndex: FrequencyIndex,
  result: DetailedResult | null
): DetailedPropertyGroup[] {
  const resultLog: DetailedPropertyGroup[] = [];
  resultLog.push({
    heading: "Inputs & Constants",
    properties: [
      {
        symbol: "W_0",
        title: "Power of sound source",
        sciRef: "",
        value: W_0,
        unit: "W",
        decimals: -1
      },
      {
        symbol: "T",
        title: "Ambient atmospheric temperature",
        sciRef: "",
        value: atmosphereInput.T,
        unit: "K",
        decimals: -1
      },
      {
        symbol: "RH",
        title: "Relative humidity",
        sciRef: "",
        value: atmosphereInput.hum_rel,
        unit: "%",
        decimals: -1
      },
      {
        symbol: "pa",
        title: "Ambient atmospheric pressure",
        sciRef: "",
        value: atmosphereInput.pa,
        unit: "Pa",
        decimals: -1
      },
      ...roomInput.map((dir, i) => ({
        symbol: `L_${"xyz"[i]}`,
        title: `Length of the room in ${"xyz"[i]} direction`,
        sciRef: "",
        value: dir.L,
        unit: "m",
        decimals: -1
      })),
      {
        symbol: "f",
        title: "Frequency of sound",
        sciRef: "",
        value: FREQUENCIES[frequencyIndex],
        unit: "Hz",
        decimals: -1
      },
      ...roomInput.map((dir, i) => ({
        symbol: `α_{${"xyz"[i]},f}`,
        title: `${"XYZ"[i]}-Absorption at frequency f`,
        sciRef: "",
        value: dir.alpha[frequencyIndex],
        unit: "",
        decimals: -1
      })),
      ...roomInput.map((dir, i) => ({
        symbol: `Sc_{${"xyz"[i]},f}`,
        title: `${"XYZ"[i]}-Scattering at frequency f`,
        sciRef: "",
        value: dir.sc[frequencyIndex],
        unit: "",
        decimals: -1
      })),
      {
        symbol: "t",
        title: "Point of time",
        sciRef: "",
        value: timeInput,
        unit: "s",
        decimals: -1
      }
    ]
  });
  if (result === null) {
    return resultLog;
  }
  const res = result;
  const freqRes = res.octoFrequential[frequencyIndex];
  resultLog.push({
    heading: "Derived parameters",
    properties: [
      {
        symbol: "a_{air,f}",
        title: "Attenuation of air",
        sciRef: "ISO 9613-1",
        value: freqRes.a_air,
        decimals: 4,
        unit: "dB SPL/m"
      },
      {
        symbol: "m_f",
        title: "Dissipation factor of air",
        sciRef: "",
        value: freqRes.m_f,
        decimals: 4,
        unit: "m^{-1}"
      },
      {
        symbol: "ρ_0",
        title: "Density of dry air",
        sciRef: "",
        value: res.rho_0,
        decimals: 4,
        unit: "kg/m³"
      },
      {
        symbol: "c_0",
        title: "Speed of sound in dry air",
        sciRef: "",
        value: res.c_0,
        decimals: 4,
        unit: "m/s"
      },
      {
        symbol: "L_s",
        title: "Free path length of the 3D sound field in the room",
        sciRef: "Zhou et al., 2021, Eq. 7",
        value: res.L_s,
        decimals: 4,
        unit: "m"
      },
      {
        symbol: "α_s",
        title: "Area weighted average absorption factor of the room",
        sciRef: "Zhou et al., 2021, Eq. 4 and 17",
        value: freqRes.alpha_s,
        decimals: 4,
        unit: ""
      },
      {
        symbol: "A_e",
        title:
          "Expression of Eyring for equivalent sound absorption area = -ln(1-α_s)*S",
        sciRef: "Zhou et al., 2021, Eq. 4",
        value: freqRes.A_e,
        decimals: 4,
        unit: "m²"
      },
      {
        symbol: "A",
        title: "Sabine: Equivalent sound absorption area of the room = α_s * S",
        sciRef: "DIN 18041:2016-03, 4.3.3/3.14",
        value: freqRes.A_s,
        decimals: 4,
        unit: "m²"
      },
      {
        symbol: "V",
        title: "Volume of the room",
        sciRef: "DIN 18041:2016-03, 3.16",
        value: res.V,
        decimals: 4,
        unit: "m³"
      },
      {
        symbol: "S",
        title: "Total surface area of the room",
        sciRef: "",
        value: res.S,
        decimals: 4,
        unit: "m²"
      },
      {
        symbol: "A\u202F/\u202FV",
        title:
          "Ratio between the equivalent sound absorption area and the room volume.",
        sciRef: "DIN 18041:2016-03, 4.3.3",
        value: freqRes.AV,
        decimals: 4,
        unit: "m²/m³"
      },
      ...freqRes.triDimensional.map((res_i, i) => ({
        symbol: `Br_{${"xyz"[i]},f}`,
        title: `Lateral sound absorption for ${"xyz"[i]} direction`,
        sciRef: "Zhou et al., 2021, Eq. 12",
        value: res_i.BR_i,
        unit: "",
        decimals: -1
      }))
    ]
  });
  resultLog.push({
    heading: "Effective directional coefficients",
    properties: [
      ...freqRes.triDimensional.map((res_i, i) => ({
        symbol: `Br_{${"xyz"[i]}e,f}`,
        title: `Effective lateral sound absorption for ${"xyz"[i]} direction`,
        sciRef: "Zhou et al., 2021, Eq. 9",
        value: res_i.BR_ie,
        unit: "",
        decimals: -1
      })),
      ...freqRes.triDimensional.map((res_i, i) => ({
        symbol: `α_{${"xyz"[i]}e,f}`,
        title: `Effective ${"xyz"[i]}-absorption at frequency f`,
        sciRef: "Zhou et al., 2021, Eq. 10",
        value: res_i.alpha_ie,
        unit: "",
        decimals: -1
      })),
      ...freqRes.triDimensional.map((res_i, i) => ({
        symbol: `Sc_{${"xyz"[i]}e,f}`,
        title: `Effective ${"xyz"[i]}-scattering `,
        sciRef: "Zhou et al., 2021, Eq. 11",
        value: res_i.SC_ie,
        unit: "",
        decimals: -1
      }))
    ]
  });
  resultLog.push({
    heading: "Initial levels",
    properties: [
      ...freqRes.triDimensional.flatMap((res_i, i) => [
        {
          symbol: `P^2_{${"xyz"[i]}0}`,
          title: `Initial ${"xyz"[i]} squared sound pressure`,
          sciRef: "Zhou et al., 2021, Eq. 16 resp. 8",
          value: res_i.P2_i0,
          decimals: 4,
          unit: "Pa^2"
        },
        {
          symbol: `SPL_{${"xyz"[i]}0}`,
          title: `Initial ${"xyz"[i]} SPL`,
          sciRef: "",
          value: soundPressureLevel(res_i.P2_i0),
          decimals: 4,
          unit: "dB SPL"
        }
      ]),
      {
        symbol: `P^2_{s0}`,
        title: `Initial diffuse squared sound pressure `,
        sciRef: "Zhou et al., 2021, Eq. 17 resp. 6",
        value: freqRes.P2_s0,
        decimals: 4,
        unit: "Pa^2"
      },
      {
        symbol: `SPL_{s0}`,
        title: "Initial diffuse SPL",
        sciRef: "",
        value: soundPressureLevel(freqRes.P2_s0),
        decimals: 4,
        unit: "dB SPL"
      }
    ]
  });

  resultLog.push({
    heading: "Levels after t seconds",
    properties: [
      ...freqRes.triDimensional.flatMap((res_i, i) => [
        {
          symbol: `N_${"xyz"[i]}(t)`,
          title: `Number of wall reflections in ${
            "xyz"[i]
          }-dimension after time t`,
          sciRef: "Zhou et al., 2021, Eq. 14",
          value: numberOfWallReflections(roomInput[i].L, res.c_0, timeInput),
          unit: "",
          decimals: -1
        },
        {
          symbol: `P^2_${"xyz"[i]}(t)`,
          title: `${"xyz"[i]} squared sound pressure after t seconds`,
          sciRef: "Zhou et al., 2021, Eq. 16",
          value: res_i.P2_i_t(timeInput),
          decimals: 4,
          unit: "Pa^2"
        },
        {
          symbol: `SPL_${"xyz"[i]}(t)`,
          title: `${"xyz"[i]}-SPL after t seconds`,
          sciRef: "",
          value: soundPressureLevel(res_i.P2_i_t(timeInput)),
          decimals: 4,
          unit: "dB SPL"
        }
      ]),
      {
        symbol: `P^2_s(t)`,
        title: "Diffuse squared sound pressure after t seconds",
        sciRef: "Zhou et al., 2021, Eq. 17",
        value: freqRes.P2_s_t(timeInput),
        decimals: 4,
        unit: "Pa^2"
      },
      {
        symbol: `SPL_s(t)`,
        title: "Diffuse SPL after t seconds",
        sciRef: "",
        value: soundPressureLevel(freqRes.P2_s_t(timeInput)),
        decimals: 4,
        unit: "dB SPL"
      }
    ]
  });
  resultLog.push({
    heading: "Summed levels",
    properties: [
      {
        symbol: `P^2_{sum,0}`,
        title: "Summed initial squared sound pressure",
        sciRef: "Zhou et al., 2021, Eq. 18",
        value: freqRes.P2_sum0,
        decimals: 4,
        unit: "Pa^2"
      },
      {
        symbol: `SPL_{sum,0}`,
        title: "Summed initial SPL",
        sciRef: "",
        value: soundPressureLevel(freqRes.P2_sum0),
        decimals: 4,
        unit: "dB SPL"
      },
      {
        symbol: `P^2_{sum}(t)`,
        title: "Summed squared sound pressure after t seconds",
        sciRef: "Zhou et al., 2021, Eq. 18",
        value: freqRes.P2_sum_t(timeInput),
        decimals: 4,
        unit: "Pa^2"
      },
      {
        symbol: `SPL_{sum}(t)`,
        title: "Summed SPL after t seconds",
        sciRef: "",
        value: soundPressureLevel(freqRes.P2_sum_t(timeInput)),
        decimals: 4,
        unit: "dB SPL"
      },
      {
        symbol: `SPL_{rel}(t)`,
        title: "Summed SPL after t seconds relative to initial level",
        sciRef: "",
        value: freqRes.SPL_dB_relative(timeInput),
        decimals: 4,
        unit: "dB"
      }
    ]
  });
  return resultLog;
}
