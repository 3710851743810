






















import { formatMathHTML } from "@/helper/math-format";
import { Component, Prop, VModel, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ValueInput extends Vue {
  @VModel({ type: Number })
  inputValue!: number;

  @Prop()
  unit!: string;

  @Prop({ type: String, default: null, required: false })
  symbol!: string;

  @Prop({ type: String, default: null })
  title!: string | null;

  @Prop({ type: Array, default: null, required: false })
  options!: number[] | null;

  @Prop({ type: String, default: null, required: false })
  icon!: string | null;

  get symbolHTML() {
    return this.title || formatMathHTML(this.symbol);
  }
  get unitHTML() {
    return this.unit ? formatMathHTML(this.unit) : undefined;
  }

  get optionsKeyValue() {
    return this.options?.map((f, fIdx) => ({ text: f, value: fIdx }));
  }
}
