















































































































import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import {
  Material,
  ABSORPTION_DATABASE,
  SCATTERING_DATABASE
} from "@/res/materials-absorption";

import MultiFreqDiagram from "@/components/MultiFreqDiagram.vue";
import { OctoValuePlot } from "@/helper/diagram";
import AbsorptionScatteringThumbnail from "./AbsorptionScatteringThumbnail.vue";
import { FREQUENCIES } from "@/calc/acoustic-constants";

@Component({
  components: { MultiFreqDiagram, AbsorptionScatteringThumbnail }
})
export default class MaterialInput extends Vue {
  private modal: boolean = false;
  @VModel({ type: Number })
  inputValue!: number;

  @Prop({ type: String })
  type!: "absorption" | "scattering";

  get yLabel(): string {
    return this.type === "absorption"
      ? "Absorption coefficient"
      : "Scattering coefficient";
  }

  get frequencies() {
    return FREQUENCIES;
  }

  get options(): Material[] {
    if (this.type === "scattering") {
      return [...SCATTERING_DATABASE.values()];
    } else {
      return [...ABSORPTION_DATABASE.values()];
    }
  }
  get currentMaterial(): Material {
    let dB;
    if (this.type === "scattering") {
      dB = SCATTERING_DATABASE;
    } else {
      dB = ABSORPTION_DATABASE;
    }
    const mat = dB.get(this.inputValue);
    if (mat === undefined) {
      return {
        title: "?",
        id: this.inputValue,
        values: [0, 0, 0, 0, 0, 0, 0, 0],
        description: "No material selected",
        source: ""
      } as Material;
    }
    return mat;
  }
  get octoFreqPlots(): OctoValuePlot[] {
    return [
      {
        values: this.currentMaterial.values,
        label: this.currentMaterial.title,
        drawingStyle: "line",
        bullet: "circle",
        dominant: true
      }
    ];
  }
  open() {
    this.modal = true;
  }
  close() {
    this.modal = false;
  }
  log(t: any) {
    console.log(t);
  }
}
