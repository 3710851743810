import { Atmosphere } from "@/calc/acoustic-constants";
import { DIN_SCENARIOS } from "@/calc/din-requirements";
import { SixRoomWalls, TriDimensional } from "@/calc/room";
import { CalculationState } from "@/state/state";

/** Creates a complete Bookmark-Link including the bookmark code */
export function createBookmarkLink(calc: CalculationState): string {
  return (
    window.location.origin +
    window.location.pathname +
    "#/calc/" +
    encodeBookmark(calc)
  );
}

/** Creates a bookmark code from calculation parameters. */
export function encodeBookmark(calc: CalculationState): string {
  const version = "A"; // this is version "A" encoding. Other encodings might follow in the future...
  const code = encodeBase64URL(compressSettings(calc));
  const hash = tinyChecksum(code);
  return version + hash + code;
}

/** Decodes calculation parameters from bookmark code. */
export function decodeBookmark(bookmark: string): CalculationState {
  if (bookmark.length <= 5) {
    throw new Error("Bookmark link to short.");
  }
  const version = bookmark[0];
  if (version !== "A") {
    throw new Error("Bookmark encoding unsupported by this App version.");
  }
  const hash = bookmark.substr(1, 4);
  const code = bookmark.substring(5);
  if (tinyChecksum(code) !== hash) {
    throw new Error("Checksum failed. Bookmark link incomplete or typo.");
  }
  return decompressSettings(decodeBase64URL(code));
}

/**
 * convert a Unicode string to a string in which
 * each 16-bit unit occupies only one byte
 * (Source: https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/btoa)
 *
 * */
export function packUTF16IntoUTF8(string: string) {
  const codeUnits = new Uint16Array(string.length);
  for (let i = 0; i < codeUnits.length; i++) {
    codeUnits[i] = string.charCodeAt(i);
  }
  return String.fromCharCode(...new Uint8Array(codeUnits.buffer));
}

/**
 * Reverses the conversion of packUTF16IntoUTF8()
 */
export function unpackUTF16FromUTF8(binary: string): string {
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  return String.fromCharCode(...new Uint16Array(bytes.buffer));
}

/** Replaces critical characters for URL compatible string */
export function encodeBase64URL(settingsObj: any) {
  const substitution: { [key: string]: string } = {
    "+": "-",
    "/": "_",
    "=": ""
  };
  return btoa(JSON.stringify(settingsObj)).replace(
    /[+/=]/g,
    match => substitution[match]
  );
}
export function decodeBase64URL(base64String: string): any {
  const substitution: { [key: string]: string } = {
    "-": "+",
    _: "/"
  };
  return JSON.parse(
    atob(base64String.replace(/[-_]/g, match => substitution[match]))
  );
}

/** Returns a new material-dictionary, that can be used to index material ids that are used in the url */
export function makeDict(): MaterialDictionary {
  const _list: number[] = [];
  const _reverse: { [key: number]: number } = {};
  return {
    index(m: number) {
      if (Object.prototype.hasOwnProperty.call(_reverse, m)) {
        return _reverse[m];
      } else {
        const index = _list.push(m) - 1;
        _reverse[m] = index;
        return index;
      }
    },
    getList() {
      return Array.from(_list);
    }
  };
}
/** Returns the compressed roomWalls while adding their materials to the given dictionaries. */
export function compressRoomWalls(
  matAlphaDict: MaterialDictionary,
  matScDict: MaterialDictionary,
  roomWalls: SixRoomWalls
): compressedRoomWalls {
  return roomWalls
    .map(w =>
      w
        .map(
          a =>
            `${a.area},${matAlphaDict.index(a.mat)},${matScDict.index(a.matSc)}`
        )
        .join(",")
    )
    .join("|");
}

export function decompressRoomWalls(
  matAlpha: compressedMaterials,
  matSc: compressedMaterials,
  roomWalls: compressedRoomWalls
): SixRoomWalls {
  const sixWalls = roomWalls.split("|").map(w => {
    const ungrouped = w.split(",");
    const wall = [];
    if (ungrouped.length % 3 !== 0 || ungrouped.length === 0) {
      throw Error("Material Assignments corrupted");
    }
    for (let i = 0; i < ungrouped.length - 2; i += 3) {
      wall.push({
        area: ungrouped[i] === "null" ? null : Number.parseFloat(ungrouped[i]),
        mat: matAlpha[Number.parseFloat(ungrouped[i + 1])],
        matSc: matSc[Number.parseFloat(ungrouped[i + 2])]
      });
    }
    return wall;
  });

  if (sixWalls.length !== 6) {
    throw Error("corrupted URL: wall count is not 6");
  }
  return sixWalls as SixRoomWalls;
}

export interface MaterialDictionary {
  /**
   * Adds a material to the dictionary and returns the index.
   * If the material already exists just returns the index of the existing.
   * */
  index(m: number): number;
  /** Returns an array copy of the dictionary  */
  getList(): number[];
}

export type compressedMaterials = number[];
export type compressedRoomWalls = string;

export function compressSettings(
  calculation: CalculationState
): SettingsPortable {
  const matAlphaDict = makeDict();
  const matScDict = makeDict();
  return [
    calculation.roomSize[0],
    calculation.roomSize[1],
    calculation.roomSize[2],
    calculation.atmosphere.T,
    calculation.atmosphere.hum_rel,
    calculation.atmosphere.pa,
    calculation.dinScenario,
    compressRoomWalls(matAlphaDict, matScDict, calculation.roomWalls),
    matAlphaDict.getList(),
    matScDict.getList()
  ];
}

export type SettingsPortable = [
  number,
  number,
  number,
  Atmosphere["T"],
  Atmosphere["hum_rel"],
  Atmosphere["pa"],
  DIN_SCENARIOS,
  compressedRoomWalls,
  compressedMaterials,
  compressedMaterials
];

export function decompressSettings(
  compressedSettings: SettingsPortable
): CalculationState {
  if (compressedSettings.length !== 10) {
    throw Error(
      "Compressed settings array has the wrong length. Excpected 10, but got " +
        compressedSettings.length +
        " elements."
    );
  }
  const roomSize: TriDimensional<number> = [
    compressedSettings[0],
    compressedSettings[1],
    compressedSettings[2]
  ];
  const atmosphere: Atmosphere = {
    T: compressedSettings[3],
    hum_rel: compressedSettings[4],
    pa: compressedSettings[5]
  };
  const dinScenario: DIN_SCENARIOS = compressedSettings[6];
  if (!Object.values(DIN_SCENARIOS).includes(dinScenario)) {
    throw new Error(`Unknown value for DIN scenario.`);
  }
  const roomWalls: SixRoomWalls = decompressRoomWalls(
    compressedSettings[8],
    compressedSettings[9],
    compressedSettings[7]
  );
  return { atmosphere, roomSize, dinScenario, roomWalls };
}

/** inspired by https://stackoverflow.com/a/7616484 */
export function tinyChecksum(code: string): string {
  let hash = 0,
    i,
    chr;
  if (code.length !== 0) {
    for (i = 0; i < code.length; i++) {
      chr = code.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
  }
  return hash
    .toString(32)
    .slice(-4) // only use the last 4 chars
    .padEnd(4, "U"); // make sure the result is always 4 chars long
}
