import {
  ABSORPTION_DATABASE,
  Material,
  SCATTERING_DATABASE,
  FALLBACK_ABSORPTION_MATERIAL,
  FALLBACK_SCATTERING_MATERIAL
} from "@/res/materials-absorption";
import { OctoFrequential, SixRoomWalls, TriDimensional, octoZip } from "./room";
import { wallsTotalArea } from "./sound-pressure-diffuse";

/** A part of a wall that has an absorption and scattering material assigned. */
export interface AreaAssignment {
  /** Size of the area in m² or null for automatic size */
  area: number | null;
  /** Id of the assigned absorption material */
  mat: Material["id"];
  /** Id of the assigned scattering material */
  matSc: Material["id"];
}

export type WallConfiguration = AreaAssignment[];

/** Can be used for absorption or scattering assignments. */
export interface ProcessedAssignment {
  /** Assigned size of the area relative to the total area of the wall */
  weight: number;
  /** Absorption/Scattering values*/
  values: Readonly<OctoFrequential<number>>;
}
/** Can be used for absorption or scattering assignments. */
export type ProcessedWallConfiguration = ProcessedAssignment[];

/**
 * Distributes the remaining area evenly among the assignments marked as *auto-size*.
 * Returns an array that containes the size of each assignment for all assinments.
 * */
export function autoDivideRemainingArea(
  assignments: WallConfiguration,
  totalArea: number
): number[] {
  const realValues = assignments.filter(a => a.area !== null);
  const autoCount = assignments.length - realValues.length;
  const assignedArea = realValues.reduce<number>(
    (tot, curr) => tot + (curr.area as number),
    0
  );
  const chunkSize = (totalArea - assignedArea) / autoCount;

  return assignments.map(a => (a.area === null ? chunkSize : a.area));
}

export function processedAbsorptionAssignments(
  assignments: WallConfiguration,
  totalArea: number
): ProcessedWallConfiguration {
  const realAreaSizes = autoDivideRemainingArea(assignments, totalArea);
  return assignments.map((a, aIdx) => {
    const mat =
      ABSORPTION_DATABASE.get(a.mat)?.values ||
      ([0, 0, 0, 0, 0, 0, 0, 0] as const); // Todo: Don't fail silently!
    return {
      weight: realAreaSizes[aIdx] / totalArea,
      values: mat
    };
  });
}
export function processedScatteringAssignments(
  assignments: WallConfiguration,
  totalArea: number
): ProcessedWallConfiguration {
  const realAreaSizes = autoDivideRemainingArea(assignments, totalArea);
  return assignments.map((a, aIdx) => {
    const matSc =
      SCATTERING_DATABASE.get(a.matSc)?.values ||
      ([0, 0, 0, 0, 0, 0, 0, 0] as const); // Todo: Don't fail silently!
    return {
      weight: realAreaSizes[aIdx] / totalArea,
      values: matSc
    };
  });
}

export function wallAverage(
  processedAssignments: ProcessedWallConfiguration
): OctoFrequential<number> {
  let alpha_i: OctoFrequential<number> = [0, 0, 0, 0, 0, 0, 0, 0];
  processedAssignments.forEach(pa =>
    pa.values.forEach((fVal, fIdx) => (alpha_i[fIdx] += fVal * pa.weight))
  );
  return alpha_i;
}

export function dimensionalAverage(
  processedWall0: ProcessedWallConfiguration,
  processedWall1: ProcessedWallConfiguration
): OctoFrequential<number> {
  return octoZip(
    wallAverage(processedWall0),
    wallAverage(processedWall1),
    (a, b) => (a + b) / 2
  );
}

export function dimensionalAverageAbsorption(
  roomWalls: SixRoomWalls,
  roomSize: TriDimensional<number>
): TriDimensional<OctoFrequential<number>> {
  const wallArea = wallsTotalArea(roomSize);
  const processedWalls = roomWalls.map((w, wIdx) =>
    processedAbsorptionAssignments(w, wallArea[wIdx])
  );
  return [
    dimensionalAverage(processedWalls[0], processedWalls[1]),
    dimensionalAverage(processedWalls[2], processedWalls[3]),
    dimensionalAverage(processedWalls[4], processedWalls[5])
  ];
}
export function dimensionalAverageScattering(
  roomWalls: SixRoomWalls,
  roomSize: TriDimensional<number>
): TriDimensional<OctoFrequential<number>> {
  const wallArea = wallsTotalArea(roomSize);
  const processedWalls = roomWalls.map((w, wIdx) =>
    processedScatteringAssignments(w, wallArea[wIdx])
  );
  return [
    dimensionalAverage(processedWalls[0], processedWalls[1]),
    dimensionalAverage(processedWalls[2], processedWalls[3]),
    dimensionalAverage(processedWalls[4], processedWalls[5])
  ];
}

export function generateDefaultAreaAssignments(): WallConfiguration {
  return [
    {
      area: null,
      mat: FALLBACK_ABSORPTION_MATERIAL,
      matSc: FALLBACK_SCATTERING_MATERIAL
    }
  ];
}
export function generateInterestingRoom(): SixRoomWalls {
  return [
    generateDefaultAreaAssignments(),
    generateDefaultAreaAssignments(),
    generateDefaultAreaAssignments(),
    generateDefaultAreaAssignments(),
    generateDefaultAreaAssignments(),
    [{ area: null, mat: 237, matSc: 25201 }] // place some high absorption & scattering material on the ceiling to get a nice difference between Zhou and Sabine
  ];
}
