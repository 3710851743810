import { Atmosphere } from "@/calc/acoustic-constants";
import {
  dimensionalAverageAbsorption,
  dimensionalAverageScattering,
  generateInterestingRoom,
  WallConfiguration
} from "@/calc/area-assignment";
import {
  createExampleAtmosphere,
  default_Lx,
  default_Ly,
  default_Lz
} from "@/calc/default-inputs";
import { DIN_SCENARIOS } from "@/calc/din-requirements";
import {
  OctoFrequential,
  RoomDirectionMultiFreq,
  TriDimensional
} from "@/calc/room";
import {
  DetailedResult,
  summedSquaredSoundPressure
} from "@/calc/sound-pressure-summed";
import {
  createBookmarkLink,
  decodeBookmark,
  encodeBookmark
} from "@/helper/settings-input-output";
import Vue from "vue";

/** Contains all user input needed for calculation */
export interface CalculationState {
  atmosphere: Atmosphere;
  roomSize: TriDimensional<number>;
  dinScenario: DIN_SCENARIOS;
  roomWalls: [
    WallConfiguration,
    WallConfiguration,
    WallConfiguration,
    WallConfiguration,
    WallConfiguration,
    WallConfiguration
  ];
}

export interface AppState {
  calc: CalculationState;
  result: DetailedResult | null;
  error: Error | null;
}

const state: AppState = {
  calc: {
    atmosphere: createExampleAtmosphere(),
    roomSize: [default_Lx, default_Ly, default_Lz],
    dinScenario: DIN_SCENARIOS.A1,
    roomWalls: generateInterestingRoom()
  },
  result: null,
  error: null
};

/**
 * This app is not yet using Vuex as state management engine. Instead it uses
 * a custom store implementation that is in fact a Vue instance similar to a
 * Vue component, but without template.
 * All modifications to the state inside the store are always made in a
 * immutable fashion. This allows e.g. the Calculation.vue component to create
 * a local copy of the state for the latest changes and commit it only when the
 * user clicks "simulate/calculate".
 * State management is work in progress.
 */
class Store extends Vue {
  private state: AppState = state;
  public walls_polygonsZSorted: any;
  public walls_sizes: any;
  public walls_specs = { xMax: 0, yMax: 0 };
  public loading: boolean = false;

  get dimensionalAbsorption(): TriDimensional<OctoFrequential<number>> {
    return dimensionalAverageAbsorption(
      this.state.calc.roomWalls,
      this.state.calc.roomSize
    );
  }
  get dimensionalScattering(): TriDimensional<OctoFrequential<number>> {
    return dimensionalAverageScattering(
      this.state.calc.roomWalls,
      this.state.calc.roomSize
    );
  }
  get roomMultiFreq(): TriDimensional<RoomDirectionMultiFreq> {
    return this.state.calc.roomSize.map((L, dIdx) => ({
      L,
      alpha: this.dimensionalAbsorption[dIdx],
      sc: this.dimensionalScattering[dIdx]
    })) as TriDimensional<RoomDirectionMultiFreq>;
  }

  get result(): AppState["result"] {
    return this.state.result;
  }

  get calc(): AppState["calc"] {
    return this.state.calc;
  }

  get error(): AppState["error"] {
    return this.state.error;
  }

  calculate() {
    this.loading = true;
    this.state.result = summedSquaredSoundPressure(
      this.state.calc.atmosphere,
      this.roomMultiFreq
    );
  }
  resetCalculation() {
    this.state = {
      calc: {
        atmosphere: createExampleAtmosphere(),
        roomSize: [default_Lx, default_Ly, default_Lz],
        dinScenario: DIN_SCENARIOS.A1,
        roomWalls: generateInterestingRoom()
      },
      result: null,
      error: null
    };
  }

  setError(error: Error) {
    this.state = { ...this.state, error };
  }
  setCalc(calc: CalculationState) {
    this.state = { ...this.state, calc };
  }
  openBookmark(bookmark: string) {
    console.log("trying to open bookmark", bookmark);
    this.setCalc(decodeBookmark(bookmark));
    this.calculate();
  }
  getBookmarkCode(): string | null {
    if (!this.state.result) {
      return null;
    }
    return encodeBookmark(this.state.calc);
  }
  getBookmarkLink(): string | null {
    if (!this.state.result) {
      return null;
    }
    return createBookmarkLink(this.state.calc);
  }
}

/**
 * Singleton instance of the store that can be imported
 * into components that need access to the central state in store.
 */
export const store = new Store();

/* 
// In the future maybe use Vuex plugin for store:
import Vuex from "vuex";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {}
});
 */
