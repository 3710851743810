













































































import { RTDeviationRule } from "@/calc/din-requirements";
import { OctoFrequential } from "@/calc/room";
import { Component, Prop, Vue } from "vue-property-decorator";
import MultiFreqLine from "@/components/MultiFreqLine.vue";
import MultiFreqCorridor from "@/components/MultiFreqCorridor.vue";
import DiagramLegend from "@/components/DiagramLegend.vue";
import {
  calculateAxisMarks,
  calculateSVGViewbox,
  DIN_TARGET_LEGEND_ITEMS,
  getLegendHeight,
  getLegendPositions,
  gridXPath,
  gridYPath,
  LegendItem,
  LEGEND_ICON_OFFSET,
  OctoValuePlot,
  SVGViewBox
} from "@/helper/diagram";
import { freqSymbols } from "@/calc/acoustic-constants";

/** Draws a octo frequency diagram */
@Component({
  components: { MultiFreqLine, MultiFreqCorridor, DiagramLegend }
})
export default class MultiFreqDiagram extends Vue {
  @Prop()
  octoFreqPlots!: OctoValuePlot[];

  @Prop({ type: Number })
  yMax!: number;

  @Prop({ type: String })
  yLabel!: string;

  @Prop({ type: Number, default: 300 })
  height!: number;
  @Prop({ type: Number, default: 400 })
  width!: number;

  @Prop({
    type: Array,
    default: null,
    required: false
  })
  dinCorridor!: OctoFrequential<RTDeviationRule> | null;

  @Prop({ type: Number, default: null, required: false })
  dinTargetRT!: number | null;

  @Prop({ type: Boolean, default: false })
  showLegend!: boolean;

  private margin = 50;

  xMax: number = 7;
  xMin: number = 0;
  yMin: number = 0;
  xRes = 1; // put marks every ? units
  @Prop({ type: Number, default: 1, required: false })
  yRes!: number; // put marks every ? units

  get xScale(): number {
    return Math.floor(this.width / (this.xMax - this.xMin));
  }
  get yScale(): number {
    return Math.floor(this.height / (this.yMax - this.xMin));
  }
  get xAxisMarks() {
    return calculateAxisMarks(this.xMin, this.xMax, this.xRes);
  }
  get yAxisMarks(): number[] {
    return calculateAxisMarks(this.xMin, this.yMax, this.yRes * 0.5);
  }
  get gridXPath() {
    return gridXPath(
      this.xAxisMarks,
      this.yMin,
      this.yMax,
      this.projectX,
      this.projectY
    );
  }
  get gridYPath() {
    return gridYPath(
      this.yAxisMarks,
      this.xMin,
      this.xMax,
      this.projectX,
      this.projectY
    );
  }
  get freqSymbols(): Readonly<OctoFrequential<string>> {
    return freqSymbols;
  }
  projectX(x: number): number {
    return x * this.xScale;
  }
  projectY(y: number): number {
    return this.height - y * this.yScale;
  }
  get SVGViewbox(): SVGViewBox {
    return calculateSVGViewbox(
      this.width,
      this.height,
      this.margin,
      this.legendHeight
    );
  }
  get legendItems(): LegendItem[] {
    const items: LegendItem[] = [];
    if (this.dinCorridor !== null && this.dinTargetRT !== null) {
      items.push(...DIN_TARGET_LEGEND_ITEMS);
    }
    items.push(...(this.octoFreqPlots as LegendItem[]));
    return items;
  }
  get legendPositions(): [number, number][] {
    return getLegendPositions(this.legendItems.length, this.width, 2);
  }
  get legendHeight(): number {
    return this.showLegend ? getLegendHeight(this.legendItems.length, 2) : 0;
  }
  get legendIconOffset(): number {
    return LEGEND_ICON_OFFSET;
  }
  formatNum(x: number): string {
    return x >= 0
      ? `${x.toFixed(Math.max(1, Math.log10(1 / this.yRes)))}`
      : `−${-x}`;
  }
}
