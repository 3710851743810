




























































































































































































































































































































































































































































































































































import LoaderScreen from "@/components/LoaderScreen.vue";
import {
  FREQUENCIES,
  FrequencyIndex,
  freqSymbols
} from "@/calc/acoustic-constants";
import {
  OctoFrequential,
  RoomDirectionMultiFreq,
  TriDimensional
} from "@/calc/room";
import { DetailedResult } from "@/calc/sound-pressure-summed";
import { exportCSVTable } from "@/helper/excel-export";
import { Component, Vue } from "vue-property-decorator";
import { DetailedPropertyGroup } from "@/calc/result-log";
import { singleFreqResultLog } from "@/helper/detailed-result-log";
import { createBookmarkLink } from "@/helper/settings-input-output";
import CurveGraph from "@/components/CurveGraph.vue";
import MultiFreqDiagram from "@/components/MultiFreqDiagram.vue";

import ValueDisplay from "@/components/ValueDisplay.vue";
import ValueInput from "@/components/ValueInput.vue";
import WallEditor from "@/components/WallEditor.vue";
import {
  dimensionalAverageAbsorption,
  dimensionalAverageScattering
} from "@/calc/area-assignment";
import RoomPreview from "@/components/RoomPreview.vue";
import ExplanationHint from "@/components/ExplanationHint.vue";
import {
  RTDeviationRule,
  getRTDeviationRules,
  dinTargetReverberationTime,
  SCENARIO_SYMBOLS,
  isCategoryB,
  dinCatBMinAVRatios,
  dinCatBAVRatiosPassed
} from "@/calc/din-requirements";
import { store, CalculationState } from "@/state/state";
import { maxTimeWindow, OctoValuePlot } from "@/helper/diagram";
import SummaryRoomDimensions from "@/components/SummaryRoomDimensions.vue";
import SummaryDinScenario from "@/components/SummaryDinScenario.vue";
import SummaryAbsorptionScattering from "@/components/SummaryAbsorptionScattering.vue";
import SummaryAtmosphere from "@/components/SummaryAtmosphere.vue";

@Component({
  components: {
    LoaderScreen,
    ValueDisplay,
    ValueInput,
    CurveGraph,
    MultiFreqDiagram,
    WallEditor,
    RoomPreview,
    ExplanationHint,
    SummaryRoomDimensions,
    SummaryDinScenario,
    SummaryAbsorptionScattering,
    SummaryAtmosphere
  }
})
export default class Results extends Vue {
  private validationError: string = "";
  private tInput: number = 0.02;
  private frequencyIndexInput: FrequencyIndex = 3;
  private loaded: Boolean = !store.loading;

  created() {
    if (store.loading) {
      store.loading = false;
      this.setupLoader();
    }
  }
  setupLoader() {
    setTimeout(() => {
      this.loaded = true;
    }, 1600);
  }

  get calc(): CalculationState {
    return store.calc;
  }

  get result(): Readonly<DetailedResult> | null {
    return store.result;
  }

  /** Defines which v-expansion-panel is initially opened */
  private resultPanelState: number[] = [0, 1];

  get FREQUENCIES() {
    return FREQUENCIES;
  }
  get FREQUENCY_SYMBOLS() {
    return freqSymbols;
  }

  get roomMultiFreq(): TriDimensional<RoomDirectionMultiFreq> {
    return this.calc.roomSize.map((L, dIdx) => ({
      L,
      alpha: this.dimensionalAbsorption[dIdx],
      sc: this.dimensionalScattering[dIdx]
    })) as TriDimensional<RoomDirectionMultiFreq>;
  }

  get resultLog(): DetailedPropertyGroup[] {
    return singleFreqResultLog(
      this.calc.atmosphere,
      this.roomMultiFreq,
      this.tInput,
      this.frequencyIndexInput,
      this.result
    );
  }

  get dimensionalAbsorption(): TriDimensional<OctoFrequential<number>> {
    return dimensionalAverageAbsorption(
      this.calc.roomWalls,
      this.calc.roomSize
    );
  }
  get dimensionalScattering(): TriDimensional<OctoFrequential<number>> {
    return dimensionalAverageScattering(
      this.calc.roomWalls,
      this.calc.roomSize
    );
  }

  get decayTimes() {
    return this.result?.octoFrequential.map(
      fResult => fResult.reverberationTimeZhou
    );
  }
  get decayTimesSabine() {
    return this.result?.octoFrequential.map(
      fResult => fResult.reverberationTimeSabine
    );
  }

  get AVRatios(): OctoFrequential<number> | null {
    if (!this.result) {
      return null;
    }
    return this.result.octoFrequential.map(
      fResult => fResult.AV
    ) as OctoFrequential<number>;
  }

  get AVRatiosPassed(): OctoFrequential<boolean | null> | null {
    if (!this.AVRatios || !this.dinCatBMinAVRatios) {
      return null;
    }
    return dinCatBAVRatiosPassed(this.AVRatios, this.dinCatBMinAVRatios);
  }

  get dinCatBMinAVRatios(): OctoFrequential<number | null> | null {
    if (!isCategoryB(this.calc.dinScenario)) {
      return null;
    }
    return dinCatBMinAVRatios(this.calc.roomSize[2], this.calc.dinScenario);
  }

  get octoFreqPlots(): OctoValuePlot[] {
    return [
      {
        values: this.decayTimes as OctoFrequential<number>,
        label: "reverberate (Zhou et al.)",
        drawingStyle: "line",
        bullet: "circle",
        dominant: true
      },
      {
        values: this.decayTimesSabine as OctoFrequential<number>,
        label: "classical method (C. Sabine)",
        drawingStyle: "line",
        bullet: "triangle",
        dominant: true
      }
    ];
  }

  /** Calculates a suitable time-window for displaying the slopes */
  get maxTimeWindow(): number {
    return maxTimeWindow(this.decayTimes);
  }
  get scenarioSymbols(): Readonly<string[]> {
    return SCENARIO_SYMBOLS;
  }
  get dinTargetRT(): number | null {
    return (
      this.result && // return null if this.result===null or next line
      dinTargetReverberationTime(this.result.V, this.calc.dinScenario)
    );
  }
  get RTDeviationRules(): OctoFrequential<RTDeviationRule> | null {
    return getRTDeviationRules(this.calc.dinScenario);
  }

  get dinMinToleratedRT() {
    if (this.dinTargetRT !== null && this.RTDeviationRules !== null) {
      return this.RTDeviationRules.map(
        rule => rule.minDeviation * (this.dinTargetRT || 0)
      ) as OctoFrequential<number>;
    } else {
      return null;
    }
  }
  get dinMaxToleratedRT() {
    if (this.dinTargetRT !== null && this.RTDeviationRules !== null) {
      return this.RTDeviationRules.map(
        rule => rule.maxDeviation * (this.dinTargetRT || 0)
      ) as OctoFrequential<number>;
    } else {
      return null;
    }
  }

  copyResultLogToClipBoard(numberFormat: "de-DE" | "en-US"): void {
    exportCSVTable(this.resultLog, numberFormat);
  }

  showFullURL() {
    (this.$refs.linktext as HTMLElement).classList.add("extend-linktext");
  }

  private copyLinkSnackbar = false;

  copyLink() {
    navigator.clipboard.writeText(this.bookmark);
    this.copyLinkSnackbar = true;
  }

  get bookmark(): string {
    if (!this.result) {
      return "";
    }
    return createBookmarkLink(this.calc);
  }
}
