












































































import { Component, Vue } from "vue-property-decorator";
import CurveGraph from "@/components/CurveGraph.vue";
import MultiFreqDiagram from "@/components/MultiFreqDiagram.vue";

import ValueDisplay from "@/components/ValueDisplay.vue";
import ValueInput from "@/components/ValueInput.vue";
import WallEditor from "@/components/WallEditor.vue";
import RoomPreview from "@/components/RoomPreview.vue";
import ExplanationHint from "@/components/ExplanationHint.vue";

@Component({
  components: {
    ValueDisplay,
    ValueInput,
    CurveGraph,
    MultiFreqDiagram,
    WallEditor,
    RoomPreview,
    ExplanationHint
  }
})
export default class Home extends Vue {}
