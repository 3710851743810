
























































































































































































































































import { FREQUENCIES, freqSymbols } from "@/calc/acoustic-constants";
import { OctoFrequential, TriDimensional } from "@/calc/room";
import { Component, Prop, Vue } from "vue-property-decorator";
import CurveGraph from "@/components/CurveGraph.vue";
import MultiFreqDiagram from "@/components/MultiFreqDiagram.vue";
import Results from "@/views/Results.vue";

import ValueDisplay from "@/components/ValueDisplay.vue";
import ValueInput from "@/components/ValueInput.vue";
import WallEditor, { WallEditorState } from "@/components/WallEditor.vue";
import RoomPreview from "@/components/RoomPreview.vue";
import ExplanationHint from "@/components/ExplanationHint.vue";
import {
  DIN_SCENARIOS,
  SCENARIO_IDS,
  SCENARIO_SYMBOLS,
  SCENARIO_TITLES
} from "@/calc/din-requirements";
import { CalculationState, store } from "@/state/state";
import SummaryRoomDimensions from "@/components/SummaryRoomDimensions.vue";
import SummaryDinScenario from "@/components/SummaryDinScenario.vue";
import SummaryAbsorptionScattering from "@/components/SummaryAbsorptionScattering.vue";
import SummaryAtmosphere from "@/components/SummaryAtmosphere.vue";

@Component({
  components: {
    ValueDisplay,
    ValueInput,
    CurveGraph,
    MultiFreqDiagram,
    WallEditor,
    RoomPreview,
    SummaryRoomDimensions,
    SummaryDinScenario,
    SummaryAbsorptionScattering,
    SummaryAtmosphere,
    ExplanationHint,
    Results
  }
})
export default class Calculation extends Vue {
  /**
   * This component uses a local copy of the state that will be
   * committed to store with this.calculate()
   * */
  private localCalc: CalculationState = store.calc;

  @Prop({ default: null })
  private jumpToSection!: number | null;

  /** Holds the index of the v-expansion-panel that is currently opened */
  private expandedSection: number | null = 0;
  // Declare mounted lifecycle hook
  async mounted() {
    if (this.jumpToSection !== null) {
      this.expandedSection = this.jumpToSection;
      const scrollTarget =
        document.querySelectorAll(".v-expansion-panel")[this.jumpToSection];
      setTimeout(() => {
        // wait until panel is opened and scroll into view:
        scrollTarget.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }
  private validationError: string = "";

  /** Defines which wall is selected and if areaEditor is opened */
  private wallEditorState: WallEditorState = {
    activeWall: 0,
    showAreaEditor: false
  };

  get dinScenario() {
    return this.localCalc.dinScenario;
  }

  set dinScenario(dinScenario) {
    console.log("set dinScenari before", this.localCalc);
    this.localCalc = { ...this.localCalc, dinScenario }; // immutable
    console.log("set dinScenari", this.localCalc);
  }

  get roomSize() {
    return this.localCalc.roomSize;
  }

  set roomSize(roomSize) {
    console.log("set roomSize(r before", this.localCalc);
    this.localCalc = { ...this.localCalc, roomSize }; // immutable
    console.log("set roomSize(r", this.localCalc);
  }

  get roomWalls() {
    return this.localCalc.roomWalls;
  }

  set roomWalls(roomWalls) {
    console.log("set roomWalls( before", this.localCalc);
    this.localCalc = { ...this.localCalc, roomWalls }; // immutable
    console.log("set roomWalls(", this.localCalc);
  }

  get atmosphere() {
    return this.localCalc.atmosphere;
  }

  set atmosphere(atmosphere) {
    console.log("set atmosphere before", this.localCalc);
    this.localCalc = { ...this.localCalc, atmosphere }; // immutable
    console.log("set atmosphere", this.localCalc);
  }

  saveCalc() {
    return store.setCalc(this.localCalc);
  }

  get FREQUENCIES() {
    return FREQUENCIES;
  }
  get FREQUENCY_SYMBOLS() {
    return freqSymbols;
  }

  get dimensionalAbsorption(): TriDimensional<OctoFrequential<number>> {
    return store.dimensionalAbsorption;
  }
  get dimensionalScattering(): TriDimensional<OctoFrequential<number>> {
    return store.dimensionalScattering;
  }

  selectWallSlot(wIdx: number) {
    if (wIdx === this.wallEditorState.activeWall) {
      return;
    }
    this.wallEditorState.activeWall = wIdx as WallEditorState["activeWall"];
    this.wallEditorState.showAreaEditor = false;
  }

  collapseAllInputPanels() {
    this.expandedSection = null;
  }

  calculate() {
    store.setCalc(this.localCalc);
    store.calculate();
    this.$router.push({ name: "Results" });
  }

  get scenarioSymbols(): Readonly<string[]> {
    return SCENARIO_SYMBOLS;
  }
  /** Returns label objects for all scenarios except `DIN_SCENARIOS.NONE` */
  get scenarioLabels(): { id: number; label: string }[] {
    return SCENARIO_IDS.filter(scenID => scenID !== DIN_SCENARIOS.NONE).map(
      scenID => ({ id: scenID, label: SCENARIO_TITLES[scenID] })
    );
  }
}
